import axios from "axios/index";

import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters(['getToken', 'getCheckout', 'getDesign', 'getIds', 'getDateSelected']),
    },
    methods: {
        getTokenApi: function (resolve) {
            axios.post(localStorage.getItem('data-integration-aion-url') + '/api/sanctum/token', {
                email: process.env.VUE_APP_API_EMAIL,
                password: process.env.VUE_APP_API_PASSWORD
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                alert(error);
            });
        },
        getIdsApi: function (resolve) {
            axios.get(localStorage.getItem('data-integration-aion-url') + '/api/integrations', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.getToken()
                },
                params: {
                    id: localStorage.getItem('data-integration-aion-uuid')
                }
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                alert(error);
            });
        },
        getDatesApi: function(fromDate, toDate, resolve) {
            axios.get(localStorage.getItem('data-integration-aion-url') + '/api/getMonthlyOccupancyByExperienceIdsAndDates', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+ this.getToken()
                },
                params: {
                    uuid: localStorage.getItem('data-integration-aion-uuid'),
                    ids: this.getIds().split(','),
                    date_start: fromDate,
                    date_end: toDate
                }
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                alert(error);
            });
        },
        getExperiencesByIdApi: function (resolve) {
            axios.get(localStorage.getItem('data-integration-aion-url') + '/api/getExperiencesByIdsAndDate', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.getToken()
                },
                params: {
                    uuid: localStorage.getItem('data-integration-aion-uuid'),
                    ids: this.getIds().split(','),
                    date: this.getDateSelected(),
                }
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getPurchaseById: function (idPurchase, resolve) {
            axios.get(localStorage.getItem('data-integration-aion-url') + '/api/getPurchaseById', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.getToken()
                },
                params: {
                    uuid: localStorage.getItem('data-integration-aion-uuid'),
                    id: idPurchase,
                }
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        getPaymentMethodsApi: function (payment, resolve) {
            axios.get(localStorage.getItem('data-integration-aion-url') + '​/api/getPaymentMethodsByIds', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.getToken()
                },
                params: {
                    id: localStorage.getItem('data-integration-aion-uuid'),
                    ids: [payment]
                }
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
        },
        preparePaymentMethodApi: function (idPayment, resolve) {
            axios.get(localStorage.getItem('data-integration-aion-url') + '/api/payments/preparePayment',
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getToken()
                    },
                    params: {
                        purchase_id : this.getCheckout().id,
                        customer_id: null,
                        payment_id: null,
                        payment_type: 'calendar',
                        payment_method_id : idPayment
                    }
                }
            )
            .then((response) => {
                resolve(response.data);
            }).catch((error) => {
                console.log(error)
            })
        },
        createPurchaseApi:  function (resolve) {
            axios.post(localStorage.getItem('data-integration-aion-url') + '/api/postPurchase', this.getCheckout(), {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getToken()
                    }
                }
            )
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                if(error.response.data.error == 'invalid dates') {
                    window.onbeforeunload = function () {};
                    window.unload = function () {};

                    alert('Lo sentimos, esta sesión ya ha sido reservada por otra persona. Si deseas elegir otro día, deberás empezar de nuevo.');
                    window.location.reload();
                }
            });
        },
        updatePurchaseApi: function (resolve) {
            axios.patch(localStorage.getItem('data-integration-aion-url') + '/api/patchPurchase', this.getCheckout(), {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getToken()
                    }
                }
            )
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                if(error.response.data.error == 'invalid dates') {
                    window.onbeforeunload = function () {};
                    window.unload = function () {};

                    alert('Lo sentimos, esta sesión ya ha sido reservada por otra persona. Si deseas elegir otro día, deberás empezar de nuevo.');
                    window.location.reload();
                }
            });
        },
        cancelPurchaseApi: function (resolve) {
            axios.patch(localStorage.getItem('data-integration-aion-url') + '/api/deletePurchase', { id: this.getCheckout().id },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getToken()
                    }
                }
            ).then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                resolve();
                console.log(error);
            });
        },
        cancelPurchaseById: function (resolve, idPurchase, token) {
            axios.patch(localStorage.getItem('data-integration-aion-url') + '/api/deletePurchase', { id: idPurchase },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                }
            ).then((response) => {
                resolve(response.data);
            })
                .catch((error) => {
                    console.log(error);
                });
        }
    }
}