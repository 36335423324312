import Vue from 'vue'
import Vuex from 'vuex'
import Checkout from './../classes/Checkout';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        token: null,
        design: '',
        idPurchase: null,
        ids: null,
        experiences: [],
        paymentMethods: null,
        idsLink: null,
        multiCalendar: false,
        dateSelected: null,
        checkout: null,
        showStepOne: false,
        showStepTwo: false,
        playersSelected: 0
    },
    getters: {
        getToken: (state) => () => {
            return state.token;
        },
        getIdPurchase: (state) => () => {
            return state.idPurchase;
        },
        getIdsLink: (state) => () => {
            return state.idsLink;
        },
        getIds: (state) => () => {
            return state.ids;
        },
        getDateSelected: (state) => () => {
            return state.dateSelected;
        },
        getPaymentMethods: (state) => () => {
            return state.paymentMethods;
        },
        getMultiCalendar: (state) => () => {
            return state.multiCalendar
        },
        getState: (state) => () => {
            return state;
        },
        getCheckout: (state) => () => {
            return state.checkout
        },
        getExperiences: (state) => () => {
            return state.experiences
        },
        getDesign: (state) => () => {
            return state.design;
        },
        getFieldItems: (state) => (payload) => {
            let fieldFinal = null;
            state.checkout.items.forEach((item) => {
                if (item.id_experience === payload.id_experience && item.id_calendario === payload.id_calendario) {
                    item.fields.map((filter) => {
                        if (filter.title === payload.title) {
                            fieldFinal = filter;
                        }
                    });
                }
            });

            return fieldFinal;
        },
        getNumPlayerItems: (state) => (payload) => {
            let number = null;
            state.checkout.items.forEach((item) => {
                if (item.id_experience === payload.id_experience && item.id_calendario === payload.id_calendario) {
                    number = item.people_number;
                }
            });

            return number;
        },
        getNumPlayerMinimalItems: (state) => (payload) => {
            let number = 0;
            state.checkout.items.forEach((item) => {
                if (item.id_experience == payload.id_experience) {
                    number += parseInt(item.people_number);
                }
            });

            return state.playersSelected > 0 ? state.playersSelected : number;
        },
        getExperiencesCheckout: (state) => () => {
            return state.checkout.items.map(item => {
                return state.experiences.find(experience => experience.id === item.id_experience);
            });
        },
        isMultiPrice: (state) => () => {
            return state.isMultiple;
        },
        getPlayersSelected: (state) => () => {
            return state.playersSelected;
        },
        getDisplayMode: (state) => () => {
            return state.displayMode;
        },
        getBlockView: (state) => () => {
            return state.blockView;
        },
        canShowStepOne: (state) => () => {
            return state.showStepOne;
        },
        canShowStepTwo: (state) => () => {
            return state.showStepTwo;
        },
        isMinimal: (state) => () => {
            return state.displayMode === 'minimal';
        }
    },
    mutations: {
        refreshState: (state) => {
            state.checkout.id = null;
            state.checkout.items = [];
            state.checkout.code = null;
            state.multiCalendar = false;
        },
        updateToken: (state, payload) => {
            state.token = payload.token;
        },
        updateIdPurchase: (state, payload) => {
            state.idPurchase = payload.idPurchase;
        },
        updatePaymentsMethods: (state, payload) => {
            state.paymentMethods = payload.paymentMethods;
        },
        updateIdsLinks: (state, payload) => {
            state.idsLink = JSON.parse(payload.idsLink);
        },
        updateDesign: (state, payload) => {
            state.design = payload.design;
        },
        updateIds: (state, payload) => {
            state.ids = payload.ids;
        },
        updateExperiences: (state, payload) => {
            state.experiences = payload.experiences;
        },
        updateDateSelected: (state, payload) => {
            state.dateSelected = payload.dateSelected;
            state.multiCalendar = false;

            if (!payload.notUpdateCheckout) {
                state.checkout = new Checkout();
            }
        },
        udpateMultiCalendar: (state) => {
            state.multiCalendar = !state.multiCalendar;
            state.checkout = new Checkout();
        },
        addItem: (state, payload) => {
            state.checkout.items.push(payload.item);
        },
        changeItem: (state, payload) => {
            state.checkout.items = [payload.item];
        },
        removeItem: (state, payload) => {
            state.checkout.items = state.checkout.items.filter(item => JSON.stringify(item) !== JSON.stringify(payload.item))
        },
        removeItems: (state) => {
            state.checkout.items = [];
        },
        removeMinimalItem: (state) => {
            let item = state.checkout.items[0];
            state.checkout.items = [item];
            state.checkout.items.map((item) => {
                item.start = null;
                item.end = null;
            });
        },
        updateCheckout: (state, payload) => {
            let code = '';
            if (state.checkout) code = state.checkout.code;
            state.checkout = payload.checkout;
            state.checkout.code = code;
        },
        updateCheckoutStatus: (state, payload) => {
            state.status = payload.status;
        },
        updateCheckoutPartial: (state, payload) => {
            state.checkout.is_fractioned = payload.is_fractioned;
        },
        updateCheckoutField: (state, payload) => {
            state.checkout[payload.name] = payload.value;
        },
        updatePeopleNumber: (state, payload) => {
            state.checkout.items.forEach((item) => {
                if (item.id_experience === payload.id_experience && item.id_calendario === payload.id_calendario && item.start === payload.start) {
                    item.people_number = payload.people;
                    item.price_original = payload.price;
                }
            });
        },
        updateItemField: (state, payload) => {
            state.checkout.items.forEach((item) => {
                if (item.id_experience === payload.id_experience && item.id_calendario === payload.id_calendario && item.start === payload.start) {
                    let find = false;
                    item.fields.forEach((filter) => {
                        if (filter.title === payload.newField.title) {
                            find = true;
                            filter.value = payload.newField.value;
                            filter.name = payload.newField.name;
                            filter.amount = payload.newField.amount;
                            filter.total = payload.newField.total ?? payload.newField.amount;
                        }
                    });

                    if (!find) {
                        item.fields.push(payload.newField);
                    }
                }
            });
        },
        updateIsMultiplePrice: (state, payload) => {
            state.isMultiple = payload.experiences.every(exp => exp.price_type === 'multi_price');
        },
        updatePlayersSelected: (state, payload) => {
            state.playersSelected = payload;
        },
        updateDisplayMode: (state, payload) => {
            state.displayMode = payload;
        },
        updateBlockView: (state, payload) => {
            state.blockView = payload;
        },
        updateShowStepOne: (state, payload) => {
            state.showStepOne = payload;
        },
        updateShowStepTwo: (state, payload) => {
            state.showStepTwo = payload;
        }
    },
    actions: {},
    modules: {}
})
